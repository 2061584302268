import "./AnimatedScroll.scss";

export const AnimatedScroll = ({ items }) => {
  return (
    <div className="animated-scroll">
      <img src="logos/canva.webp" alt="Canva logo" className="item item1"></img>
      <img
        src="logos/wargaming.png"
        alt="Wargaming logo"
        className="item item2"
      ></img>
      <img
        src="logos/charlesuni.png"
        alt="Charles University logo"
        className="item item3"
      ></img>
      <img
        src="logos/commerzbank.png"
        alt="Commerzbank logo"
        className="item item4"
      ></img>
      <img
        src="logos/hypermarketfilm.png"
        alt="HypermarketFilm logo"
        className="item item5"
      ></img>
      <img
        src="logos/aidscenter.png"
        alt="Aids center logo"
        className="item item6"
      ></img>
      {/* <img src="logos/canva.webp" className="item item7"></img>
      <img src="logos/canva.webp" className="item item8"></img> */}
    </div>
  );
};
