import { Link } from "react-router-dom";
import "./RetroLink.scss";

export const RetroLink = ({ text, to, style, target, rel }) => {
  return (
    <Link
      className={"retro-link"}
      to={to}
      style={style}
      target={target}
      rel={rel}
    >
      {text}
    </Link>
  );
};
