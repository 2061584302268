import { useEffect } from "react";
import { Contact } from "./Contact";
import { HomePage } from "./HomePage";
import { Projects } from "./Projects";
import { useLocation } from "react-router-dom";

export const MainPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      <HomePage />
      <Projects />
      <Contact />
    </>
  );
};
