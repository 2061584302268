import { useRef, useState } from "react";
import "./Contact.scss";
import { RetroLink } from "./RetroLink";

import emailjs from "@emailjs/browser";
import DOMPurify from "dompurify";

export const Contact = () => {
  const [submittedAlert, setSubmittedAlert] = useState("");
  const form = useRef();

  const [formData, setFormData] = useState({
    email: "",
    message: "",
    verification: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Sanitize input to prevent XSS
    const cleanValue = DOMPurify.sanitize(value);
    setFormData({
      ...formData,
      [name]: cleanValue,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (formData.verification === undefined || formData.verification === "") {
      // Sanitize form inputs
      const sanitizedEmail = DOMPurify.sanitize(formData.email);
      const sanitizedMessage = DOMPurify.sanitize(formData.message);

      // Set the sanitized values directly into the form fields before sending
      form.current.elements.email.value = sanitizedEmail;
      form.current.elements.message.value = sanitizedMessage;

      // Now use form.current (the HTML form element) to send the form
      emailjs
        .sendForm(
          "service_w0wvr0o",
          "template_x8zilup",
          form.current,
          "4Wy8Es_q5WCc6CcvM"
        )
        .then(() => {
          setSubmittedAlert("Sent! I will reply in 2 working days.");

          // Reset form fields and clear form data state
          form.current.reset(); // Reset the form fields
          setFormData({
            email: "",
            message: "",
            verification: "",
          });
        })
        .catch((error) => {
          console.error("EmailJS error:", error);
          setSubmittedAlert("Failed to send email. Please try again later.");
        });
    }
  };

  return (
    <div className="contact" id={"contact-section"}>
      <div className="contact__wrap">
        <div className="contact__socials">
          <RetroLink to="https://github.com/notsousual" text=">GitHub" />
          <RetroLink
            to="https://www.linkedin.com/in/alisazak/"
            text=">LinkedIn"
          />
        </div>
        <form
          className="contact__form"
          ref={form}
          name="contact"
          onSubmit={sendEmail}
          autoComplete="on"
        >
          <p className="contact__form__header">Contact me!</p>

          <input type="hidden" name="form-name" value="contact" />
          <div className="contact__form__item">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              autoComplete="email"
              onChange={handleInputChange}
              required
              className="contact__form__input"
            />
          </div>

          <div className="contact__form__item">
            <label htmlFor="message">Message:</label>
            <textarea
              name="message"
              id="message"
              value={formData.message}
              onChange={handleInputChange}
              required
              autoComplete="new-message"
              className="contact__form__textarea"
            ></textarea>
          </div>
          <div className={"verification"}>
            <label htmlFor="verification">L</label>
            <input
              type="text"
              id="verification"
              name="verification"
              value={formData.verification}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit" className="contact__form__submit">
            SEND
          </button>
          <p>{submittedAlert}</p>
        </form>

        <div className="contact__stamp">
          <img className="contact__logo" src="logos/alicelogow.png" />

          <div className="contact__address">
            <p>Mánesova 1080/3, 12000, Prague, Czech Republic</p>
            <p>IČO: 21512302</p>
          </div>
        </div>
      </div>

      <video src="paper.mp4" autoPlay loop playsInline muted></video>
    </div>
  );
};
