import { useState } from "react";
import "./HomePage.scss";

import { AnimatedScroll } from "./AnimatedScroll";
import { RetroLink } from "./RetroLink";

const texts1 = [
  "look good ✨",
  "user-friendly 🚀",
  "responsive 👀",
  "bring profit 💰",
];

export const HomePage = () => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`home ${hover ? "hover-effect" : ""}`}
    >
      <h1 className="title">Alisa Zakhtarenko</h1>
      <div className="subtitle">
        <p>I'll make your product </p>
        <div className="wrapper">
          <ol>
            {texts1.map((text, index) => (
              <li key={index}>
                <span>{text}</span>
              </li>
            ))}
          </ol>
        </div>
      </div>
      <p className="manifest">How? My manifest →</p>

      <nav aria-label="Main Navigation" className="nav">
        <ul>
          <li className="nav-button">
            <RetroLink to="#projects-section" text={"Projects"} />
          </li>

          <li className="nav-button">
            <RetroLink
              to="CV_Front-end_Engineer_Alisa_Zakhtarenko_Eng.pdf"
              target="_blank"
              rel="noopener noreferrer"
              text={"CV"}
            ></RetroLink>
          </li>
          <li className="nav-button">
            <RetroLink to="#contact-section" text={"Contact"} />
          </li>
        </ul>
      </nav>
      <p className="description">
        I'm a skilled front-end developer with expertise in (UX/UI) design
      </p>
      <p className="description">
        <b>These companies trust me:</b>
      </p>
      <AnimatedScroll />
    </div>
  );
};
