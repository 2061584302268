import { Card } from "./Card";
import "./Projects.scss";

const projects = [
  {
    title: "Call to Mars, World of Tanks",
    description:
      'The "Call to Mars" is an in-game event in World of Tanks, the most popular tank battle game.',
    projectLink: "https://youtu.be/ZtNPIvocSC8?si=o_rDTARv0CW-xhHN",
    image: "wargaming.png",
  },
  {
    title: "TheAItre: when a robot writes a theatre play. Charles University.",
    description:
      "The THEaiTRE project blends computational linguistics and theater, focusing on how artificial intelligence (AI) can be used to create scripts for theatrical plays. Researchers from Charles University, DAMU, and Švandovo Theatre developed a system to generate play scripts, which were then performed to commemorate the 100th anniversary of the R.U.R. drama. The project explores the collaboration between AI and humans in creative arts, analyzing both the generated work and audience reception to improve future AI-scripted performances.",
    projectLink:
      "https://www.youtube.com/watch?v=AREf7uI2duM&list=PLh3LGphCfd9C_a-Wy43-BzH8EBlKF9a5T&index=18&ab_channel=RudolfRosa",

    codeLink: "https://github.com/ufal/THEaiTRE/tree/master",
    readMore: "https://ufal.mff.cuni.cz/grants/theaitre",
  },
  {
    title: "SLP: clinical laboratory system",
    description:
      "An internal information system created for the needs of clinical laboratories for one of the largest hospitals in Prague.",
    projectLink: "https://slp.blue/",
    image: "slp.png",
  },
  {
    title: "Canva - Mockups app",
    description:
      "A mockup app in Canva.com allows users to create realistic visual representations of designs, such as websites, mobile apps, or products, by showcasing how they would appear in real-world scenarios. With customizable templates, drag-and-drop features, and a wide variety of design elements, Canva’s mockup tool is user-friendly, enabling both beginners and professionals to create polished, professional mockups with ease.",
    projectLink: "https://canva.com",
  },

  {
    title: "EMDR therapy app",
    description:
      "This React-based web application offers accessible mental health resources by incorporating EMDR (Eye Movement Desensitization and Reprocessing) therapy techniques to manage anxiety, trauma responses, and calm the nervous system through self-administered exercises.",
    projectLink: "https://online-emdr.netlify.app/",
    codeLink: "https://github.com/notsousual/emdr-web",
    image: "emdr.png",
  },
];

export const Projects = () => {
  return (
    <div className={`projects`} id="projects-section">
      <h2 class="projects__title">I worked on:</h2>

      <div className="projects__list">
        {projects.map((item, key) => (
          <Card
            title={item.title}
            description={item.description}
            image={item.image}
            codeLink={item.codeLink}
            projectLink={item.projectLink}
            readMore={item.readMore}
            key={key}
          />
        ))}
      </div>
    </div>
  );
};
