import "./Card.scss";
import { Chip } from "./Chip";

export const Card = ({
  description,
  title,
  image,
  codeLink,
  projectLink,
  readMore,
}) => {
  return (
    <div className="card">
      {image && <img src={image} className="card__image" />}

      <div className="card__block">
        <h2>
          <b>{title}</b>
        </h2>

        <div className="card__navigation">
          {codeLink && (
            <Chip
              text={"CODE"}
              link={codeLink}
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  className="chip__svg"
                  fill="#e8eaed"
                >
                  <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H160v400Zm140-40-56-56 103-104-104-104 57-56 160 160-160 160Zm180 0v-80h240v80H480Z" />
                </svg>
              }
            />
          )}

          {projectLink && (
            <Chip
              text={"DEMO"}
              link={projectLink}
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#e8eaed"
                  className="chip__svg"
                >
                  <path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" />
                </svg>
              }
            />
          )}

          {readMore && <Chip text={"READ MORE"} link={readMore} />}
        </div>

        <p className="card__description">{description}</p>
      </div>
    </div>
  );
};
